import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

/**
 * The tailwind classes used for a CraButton. Exported for now so we can
 * use it when dealing with links without changing too much.
 *
 * TODO: Make this type be a link or button.
 */
export const CraButtonClassName =
  'inline-flex w-full rounded-md border border-transparent bg-gradient-to-r from-[#FF00FF] via-[#FF0099] to-[#FF33CC] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm uppercase disabled:opacity-50';

interface CraButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export default function CraButton({ className, ...props }: CraButtonProps) {
  return (
    <button className={classNames(CraButtonClassName, className)} {...props} />
  );
}
