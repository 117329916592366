import { ReactComponent as TwitterLogo } from '../../images/TwitterLogo.svg';
import { ReactComponent as AocLogo } from '../../images/AocLogo.svg';
import WalletConnectButton from '../wallet/WalletConnectButton';

export default function AocNavBar() {
  return (
    <div className="flex w-full flex-row justify-between px-8 py-2">
      <a href="https://artofchoice.org">
        <AocLogo />
      </a>
      <div className="float-right my-auto ml-auto flex flex-row items-center space-x-6">
        <div className="ml-8 flex flex-row space-x-6">
          <a
            href="https://twitter.com/ArtOf_Choice"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TwitterLogo className="h-5 w-5 fill-white" />
          </a>
        </div>
        <WalletConnectButton />
      </div>
    </div>
  );
}
