import { Routes, Route } from 'react-router-dom';
import FlatMint from './components/flat/FlatMint';

function AocApp() {
  return (
    <Routes>
      <Route path="/" element={<FlatMint />} />
    </Routes>
  );
}

export default AocApp;
