import { getContractAddress } from './envUtil';
import YozLabsAocAbi from '../abis/AOC.json';

const CONTRACT_ADDRESS = getContractAddress();

const ContractConfig = {
  addressOrName: CONTRACT_ADDRESS,
  contractInterface: YozLabsAocAbi.abi,
};

export default ContractConfig;
