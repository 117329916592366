import { FlatMintInfo } from '../hooks/useFlatMintInfo';
/**
 * An enum representing the status of a particular mint phase.
 *
 * TODO: Add status for if the phase or collection are minted out.
 */
enum MintPhaseStatus {
  Disabled = 'Disabled',
  Ended = 'Ended',
  NotLive = 'Not Live',
  Live = 'Live',
}

export default MintPhaseStatus;

export function getMintPhaseStatus(
  currentTime?: number,
  config?: FlatMintInfo,
): MintPhaseStatus | undefined {
  const { endTime, isEnabled, startTime } = config || {};
  if (!currentTime || !endTime || !startTime || isEnabled === undefined) {
    return undefined;
  }

  if (!isEnabled) {
    return MintPhaseStatus.Disabled;
  }

  if (currentTime < startTime) {
    return MintPhaseStatus.NotLive;
  }

  if (currentTime > endTime) {
    return MintPhaseStatus.Ended;
  }

  return MintPhaseStatus.Live;
}
