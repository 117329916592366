import { useMemo } from 'react';
import CraContractConfig from '../util/ContractConfig';
import { fetchOnceReadConfig } from '../wagmi/wagmiQueryConfig';
import {
  isTokenAllocationResult,
  getTokenAllocationFromResult,
} from '../types/TokenAllocation';
import {
  isFlatMintConfig,
  getFlatMintConfigFromResult,
} from '../types/FlatMintConfig';
import useContractReadWithType from './useContractReadWithType';
import { BigNumber } from 'ethers';

export interface FlatMintInfo {
  collectionSize?: number;
  mintsPerTransaction?: number;
  isLoading: boolean;

  isEnabled?: boolean;
  price?: BigNumber;
  startTime?: number;
  endTime?: number;
}

/**
 * A hook used to fetch basic information about an auction.
 * This also helps us avoid common casting from an ethers.utils.Result into
 * a type that we need.
 */
export default function useFlatMintInfo(): FlatMintInfo {
  const { data: flatMintConfig, isLoading: isLoadingFlatMintConfig } =
    useContractReadWithType(
      CraContractConfig,
      'flatMintConfig',
      fetchOnceReadConfig,
      (data) => {
        if (!isFlatMintConfig(data)) {
          // TODO: Throw this error in getAuctionConfigFromResult?
          throw Error('Expected FlatMintConfigResult');
        }

        return getFlatMintConfigFromResult(data);
      },
    );

  const { data: tokenAllocation, isLoading: isLoadingTokenAllocation } =
    useContractReadWithType(
      CraContractConfig,
      'tokenAllocation',
      fetchOnceReadConfig,
      (data) => {
        if (!isTokenAllocationResult(data)) {
          // TODO: Throw this error in getTokenAllocationFromResult?
          throw Error('Expected TokenAllocationResult');
        }

        return getTokenAllocationFromResult(data);
      },
    );

  const { isEnabled, price, startTime, endTime } = flatMintConfig || {};

  const { collectionSize, mintsPerTransaction } = tokenAllocation || {};

  // We consider everything to be loading until we can return all the data.
  const isLoading = !isLoadingFlatMintConfig || !isLoadingTokenAllocation;

  const result = useMemo<FlatMintInfo>(() => {
    return {
      collectionSize,
      mintsPerTransaction,
      isLoading,

      isEnabled,
      price,
      endTime,
      startTime,
    };
  }, [
    collectionSize,
    mintsPerTransaction,
    isLoading,
    isEnabled,
    price,
    endTime,
    startTime,
  ]);

  return result;
}
