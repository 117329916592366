import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { ellipsizeEthAddress } from '../../util/ethUtils';
import WalletConnectModal from './WalletConnectModal';
import CraButton, { CraButtonClassName } from '../CraButton';

/**
 * A button for connecting and disconnecting a user's wallet.
 */
export default function WalletConnectButton() {
  const [showWalletConnectModal, setShowWalletConnectModal] = useState(false);
  const { data: accountData } = useAccount();
  const { disconnect } = useDisconnect();
  const { isConnected } = useConnect();

  const buttonContent = (
    <>
      {isConnected && accountData?.address
        ? ellipsizeEthAddress(accountData.address)
        : 'Connect Wallet'}
    </>
  );

  return (
    <>
      <Menu as="div" className="relative">
        {isConnected ? (
          <Menu.Button className={CraButtonClassName}>
            {buttonContent}
          </Menu.Button>
        ) : (
          <CraButton
            onClick={() => {
              setShowWalletConnectModal(true);
            }}
          >
            {buttonContent}
          </CraButton>
        )}
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-3 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? 'bg-gray-100' : '',
                    'block w-full px-4 py-2 text-left text-sm text-gray-700',
                  )}
                  onClick={() => disconnect()}
                >
                  Disconnect
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
      <WalletConnectModal
        open={showWalletConnectModal}
        onCloseModal={() => setShowWalletConnectModal(false)}
      />
    </>
  );
}
