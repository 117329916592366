import Skeleton from 'react-loading-skeleton';
import { BigNumber } from 'ethers';
import { useEffect } from 'react';
import { useBlockNumber } from 'wagmi';
import useContractReadWithType from '../../hooks/useContractReadWithType';
import useFlatMintInfo from '../../hooks/useFlatMintInfo';
import ContractConfig from '../../util/ContractConfig';
import { THREE_MINUTES_IN_MS } from '../../wagmi/wagmiQueryConfig';
import 'react-loading-skeleton/dist/skeleton.css';
import { getFormattedEtherAmount } from '../../util/ethUtils';

function getProgress(
  totalSupply: number | undefined,
  collectionSize: number | undefined,
) {
  if (!totalSupply || !collectionSize) {
    return 0;
  }

  return (totalSupply / collectionSize) * 100;
}

export default function FlatMintStats() {
  const flatMintInfo = useFlatMintInfo();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data: totalSupply, refetch: refetchTotalSupply } =
    useContractReadWithType(
      ContractConfig,
      'totalSupply',
      { cacheTime: THREE_MINUTES_IN_MS, staleTime: Infinity },
      (data) => BigNumber.from(data).toNumber(),
    );

  useEffect(
    function refetchSupplyOnNewBlock() {
      if (!blockNumber) {
        return;
      }

      refetchTotalSupply();
    },
    [blockNumber, refetchTotalSupply],
  );

  // TODO: Disable flat mint if max collection size is reached.

  const { collectionSize, price } = flatMintInfo || {};

  return (
    <>
      <div className="mt-12 mb-12 w-3/4 w-full max-w-[500px] sm:w-[25%]">
        <div className="text-md mb-2 flex flex-row justify-center">
          {totalSupply} / {collectionSize} Minted
        </div>
        <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
          <div
            className="h-2.5 rounded-full bg-gradient-to-r from-[#FF00FF] via-[#FF0099] to-[#FF33CC]"
            style={{ width: `${getProgress(totalSupply, collectionSize)}%` }}
          ></div>
        </div>
      </div>
      <div className="mb-4 flex flex-row justify-center text-center text-2xl font-bold">
        <div className="mr-2 w-1/2">Price: </div>
        <div className="w-1/2">
          {price ? getFormattedEtherAmount(price) : <Skeleton />}
        </div>
      </div>
    </>
  );
}
