import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { useState } from 'react';

/**
 * Component for a toast that pops up when the mint transaction for a user succeeds.
 */
export default function TransactionSucceedToast() {
  const [toastViewable, setToastViewable] = useState<boolean>(true);

  return (
    <>
      {toastViewable && (
        <div
          className={
            'flex inline-flex w-[500px] flex-row items-center rounded-lg bg-white shadow-md'
          }
        >
          <div className="mr-2 rounded-l-lg bg-[#1DCC1A] p-3">
            <CheckIcon className="h-6 w-6" />
          </div>
          <div className="flex w-full flex-row items-center justify-between">
            <p className="text-sm font-bold text-black">
              Transaction submitted!
            </p>
            <div className="float-right mr-4 flex">
              <button
                onClick={() => setToastViewable(false)}
                className="my-auto"
              >
                <XIcon className="h-4 w-4 fill-black" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
