import { utils as ethersUtils } from 'ethers';

/**
 * An interface representing a TokenAllocation struct in the contract.
 */
export interface TokenAllocation {
  collectionSize: number;
  mintsPerTransaction: number;
}

/**
 * A typed equivalent of an ethers.utils.Result returned when reading
 * from the contract.
 */
export type TokenAllocationResult = [
  collectionSize: number,
  mintsPerTransaction: number,
];

/**
 * A typeguard used to determine whether or not an ethers.utils.Result is
 * a Token Result.
 * @param tokenAllocationResult - The result to test.
 * @returns - Whether or not auctionConfigResult is a TokenResult.
 */
export function isTokenAllocationResult(
  tokenAllocationResult: ethersUtils.Result | undefined,
): tokenAllocationResult is TokenAllocationResult {
  return (
    Array.isArray(tokenAllocationResult) &&
    typeof tokenAllocationResult[0] === 'number' &&
    typeof tokenAllocationResult[1] === 'number'
  );
}

/**
 * Converts a TokenAllocationResult into a TokenAllocation.
 * @param - The TokenAllocationResult to convert.
 * @returns - A TokenAllocation representation of a TokenAllocationResult.
 */
export function getTokenAllocationFromResult([
  collectionSize,
  mintsPerTransaction,
]: TokenAllocationResult): TokenAllocation {
  return {
    collectionSize,
    mintsPerTransaction,
  };
}
