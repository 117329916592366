import { BigNumber, utils as ethersUtils } from 'ethers';

/**
 * An interface representing a FlatMintConfig struct in the contract.
 */
export interface FlatMintConfig {
  isEnabled: boolean;
  price: BigNumber;
  startTime: number;
  endTime: number;
}

/**
 * A typed equivalent of an ethers.utils.Result returned when reading
 * from the contract.
 */
export type FlatMintConfigResult = [
  isEnabled: boolean,
  price: BigNumber,
  startTime: number,
  endTime: number,
];

/**
 * A typeguard used to determine whether or not an ethers.utils.Result
 * is a FlatMintConfigResult.
 * @param flatMintConfigResult - The result to test.
 * @returns - Whether or not flatMintConfigResult is a FlatMintConfigResult.
 */
export function isFlatMintConfig(
  flatMintConfigResult: ethersUtils.Result | undefined,
): flatMintConfigResult is FlatMintConfigResult {
  return (
    Array.isArray(flatMintConfigResult) &&
    typeof flatMintConfigResult[0] === 'boolean' &&
    BigNumber.isBigNumber(flatMintConfigResult[1]) &&
    typeof flatMintConfigResult[2] === 'number' &&
    typeof flatMintConfigResult[3] === 'number'
  );
}

/**
 * Converts an FlatMintConfigResult into an FlatMintConfig.
 * @param - The FlatMintConfigResult to convert.
 * @returns - A FlatMintConfig representation of a FlatMintConfigResult.
 */
export function getFlatMintConfigFromResult([
  isEnabled,
  price,
  startTime,
  endTime,
]: FlatMintConfigResult): FlatMintConfig {
  return {
    isEnabled: isEnabled,
    price,
    startTime: startTime,
    endTime: endTime,
  };
}
