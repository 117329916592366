import { BigNumber, ethers } from 'ethers';
import { useCallback, useEffect, useState } from 'react';
import { useBlockNumber, useContract, useProvider } from 'wagmi';
import ContractConfig from '../../util/ContractConfig';
import { getFormattedEtherAmount } from '../../util/ethUtils';
import { FlatMintEvent, isFlatMintEvent } from '../../types/FlatMintEvent';

export default function FlatMintTotalRaised() {
  const provider = useProvider();
  const contract: ethers.Contract = useContract({
    ...ContractConfig,
    signerOrProvider: provider,
  });
  const { data: blockNumber } = useBlockNumber({ watch: true });

  const [totalEthRaised, setTotalEthRaised] = useState<BigNumber | undefined>();

  const fetchTotalEthRaised = useCallback(
    async function fetchTotalEthRaised() {
      const eventFilter = contract.filters.FlatMint();
      const flatMintEvents = await contract.queryFilter(eventFilter);
      const historicalFlatMints = flatMintEvents.filter(
        (e): e is FlatMintEvent => isFlatMintEvent(e),
      );

      const ethRaisedAmount = historicalFlatMints.reduce(
        (previousTotal, currentEvent) => {
          const { price, quantity } = currentEvent.args;
          return previousTotal.add(price.mul(quantity));
        },
        BigNumber.from(0),
      );

      if (totalEthRaised !== undefined && totalEthRaised.eq(ethRaisedAmount)) {
        return;
      }

      setTotalEthRaised(ethRaisedAmount);
    },
    [contract, totalEthRaised],
  );

  useEffect(
    function updateEthRaisedOnNewBlock() {
      if (!blockNumber) {
        return;
      }

      fetchTotalEthRaised();
    },
    [blockNumber, fetchTotalEthRaised],
  );

  useEffect(() => {
    fetchTotalEthRaised();
  }, [fetchTotalEthRaised]);

  return (
    <div className="mt-8 flex flex-col items-center justify-center space-y-3">
      <div className="text-3xl font-bold">
        {totalEthRaised ? getFormattedEtherAmount(totalEthRaised) : ''}
      </div>
      <div>Raised For Charity</div>
    </div>
  );
}
