import { utils as ethersUtils } from 'ethers';
import { useContractRead } from 'wagmi';

type UseContractReadArgs = Parameters<typeof useContractRead>;

// Remove the 'data' key on UseContractReadArgs, since it's typed as
// ethersUtils.Result | undefined.
type UseContractReadReturnWithNoData = Omit<
  ReturnType<typeof useContractRead>,
  'data'
>;

// Re-add data to the return type, using a generic type T.
type GenericUseContractReadReturnType<T> = UseContractReadReturnWithNoData & {
  data: T | undefined;
};

/**
 * Typed wrapper around useContractRead with an additional transformer,
 * allowing you to type data as a generic T.
 *
 * TODO: Also type onSuccess, onError, onSettled if we need them.
 *
 * @param contractConfig - https://wagmi.sh/docs/hooks/useContractRead#contractconfig
 * @param functionName - https://wagmi.sh/docs/hooks/useContractRead#contractconfig
 * @param config - https://wagmi.sh/docs/hooks/useContractRead#configuration
 * @param transformer - A function that transforms an ethersUtils.Result to T.
 *
 * @returns - https://wagmi.sh/docs/hooks/useContractRead#return-value, with
 * data typed as T.
 */
export default function useContractReadWithType<T>(
  contractConfig: UseContractReadArgs[0],
  functionName: UseContractReadArgs[1],
  config: UseContractReadArgs[2],
  transformer: (data: ethersUtils.Result) => T,
): GenericUseContractReadReturnType<T> {
  const useContractReadResult = useContractRead(
    contractConfig,
    functionName,
    config,
  );

  const { data } = useContractReadResult;

  const transformedData = data
    ? // By calling the function only if `data` is truthy, we simplify the type
      // of transformer.
      transformer(data)
    : undefined;

  return { ...useContractReadResult, data: transformedData };
}
