import AocLayout from '../layout/AocLayout';
import FlatMintActions from './FlatMintActions';
import MintPhaseStatus, {
  getMintPhaseStatus,
} from '../../types/MintPhaseStatus';
import useFlatMintInfo from '../../hooks/useFlatMintInfo';
import 'react-loading-skeleton/dist/skeleton.css';
import FlatMintTotalRaised from './FlatMintTotalRaised';
import { ReactComponent as OpenseaLogo } from '../../images/OpenseaLogo.svg';
import FlatMintStats from './FlatMintStats';

export default function FlatMint() {
  const currentTime = Math.floor(Date.now() / 1000);

  const flatMintInfo = useFlatMintInfo();

  const { price } = flatMintInfo || {};

  // TODO: Disable flat mint if max collection size is reached.
  const mintPhaseStatus = getMintPhaseStatus(currentTime, flatMintInfo);

  return (
    <div className="inline">
      <AocLayout>
        <div>
          <h1 className="text-4xl font-bold sm:text-5xl">Art of Choice Mint</h1>
        </div>
        <FlatMintStats />
        <FlatMintActions
          disabled={mintPhaseStatus !== MintPhaseStatus.Live}
          price={price}
        />
        <FlatMintTotalRaised />
        <a
          href="https://opensea.io/collection/artofchoice"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-8 inline-flex rounded-lg bg-black px-8 py-2"
        >
          <OpenseaLogo className="my-auto mr-4 h-5 w-5" />
          View on OpenSea
        </a>
      </AocLayout>
    </div>
  );
}
