import { useEffect, useState } from 'react';
import { BigNumber, Contract } from 'ethers';
import {
  useConnect,
  useContract,
  useContractWrite,
  useSigner,
  useWaitForTransaction,
} from 'wagmi';
import ContractConfig from '../../util/ContractConfig';
import CraButton from '../CraButton';
import toast from 'react-hot-toast';
import ErrorToast from '../toast/ErrorToast';
import FlatMintSuccessModal from './FlatMintSuccessModal';
import useFlatMintInfo from '../../hooks/useFlatMintInfo';
import useContractReadWithType from '../../hooks/useContractReadWithType';
import { fetchOnceReadConfig } from '../../wagmi/wagmiQueryConfig';
import TransactionSucceedToast from '../toast/TransactionSucceedToast';
import FlatMintNftPayButton from './FlatMintNftPayButton';

const TRANSACTION_SUCCESS = 1;
const TRANSACTION_FAILURE = 0;

function getBidButtonText(
  isSendingMintTransaction: boolean,
  isWaitingForMint: boolean,
  isConnected: boolean,
) {
  if (isSendingMintTransaction || isWaitingForMint) {
    return 'minting';
  }

  if (isConnected) {
    return 'mint';
  }

  return 'not connected';
}

interface FlatMintActionsProps {
  disabled: boolean;
  price?: BigNumber;
}

export default function FlatMintActions(props: FlatMintActionsProps) {
  const { disabled, price } = props;
  const [quantityToMint, setQuantityToMint] = useState(1);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { data: signer } = useSigner();
  const { isConnected } = useConnect();
  const flatMintConfig = useFlatMintInfo();
  const { collectionSize } = flatMintConfig || {};

  // Rely on re-fetching in FlatMintStats.
  const { data: totalSupply } = useContractReadWithType(
    ContractConfig,
    'totalSupply',
    fetchOnceReadConfig,
    (data) => BigNumber.from(data).toNumber(),
  );

  const contractConfigWithSigner = {
    ...ContractConfig,
    signerOrProvider: signer || undefined,
  };

  const craContract: Contract = useContract(contractConfigWithSigner);

  const {
    data: mintTransactionResult,
    writeAsync: mintFromContract,
    isLoading: isSendingMintTransaction,
  } = useContractWrite(contractConfigWithSigner, 'flatMint');

  const mintTransactionHash = mintTransactionResult?.hash;

  const { isLoading: isWaitingForMint, data: mintTxnData } =
    useWaitForTransaction({
      hash: mintTransactionHash,
      confirmations: 1,
    });

  async function mint(numNfts: number, price: BigNumber) {
    const value = price.mul(numNfts);

    const estimate = await craContract.estimateGas.flatMint(numNfts, {
      value,
    });

    return await mintFromContract({
      args: numNfts,
      overrides: {
        value,
        gasLimit: BigNumber.from(estimate.mul(2)),
      },
    });
  }

  useEffect(
    function showToastOnTransactionComplete() {
      if (!mintTransactionHash || !mintTxnData) {
        return;
      }

      if (isWaitingForMint) {
        return;
      }

      if (mintTxnData.status === TRANSACTION_SUCCESS) {
        setShowSuccessModal(true);
        return;
      }

      if (mintTxnData.status === TRANSACTION_FAILURE) {
        toast.custom(<ErrorToast message={'Transaction failed.'} />);
        return;
      }
    },
    [isWaitingForMint, mintTransactionHash, mintTxnData],
  );

  const isMintedOut =
    totalSupply && collectionSize && totalSupply === collectionSize;

  const disableUi =
    disabled ||
    isMintedOut ||
    !isConnected ||
    !price ||
    isSendingMintTransaction ||
    isWaitingForMint;

  return (
    <div>
      <div className="mb-2 w-56">
        <div className="mb-2 flex flex-row justify-between">
          <label htmlFor="quantity" className="block text-sm font-bold">
            Quantity
          </label>
          <label htmlFor="quantity" className="block text-sm">
            Max 8
          </label>
        </div>
        <select
          id="quantity"
          name="quantity"
          className="mt-1 block w-full rounded-md border-2 border-white bg-black py-2 pl-3 pr-10  text-base focus:outline-none sm:text-sm"
          disabled={disableUi}
          value={quantityToMint}
          onChange={(event) => {
            setQuantityToMint(parseInt(event.target.value));
          }}
        >
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
        </select>
      </div>
      <div className="mt-4">
        <CraButton
          className="justify-center"
          disabled={disableUi}
          onClick={async () => {
            if (!price) {
              return;
            }
            try {
              await mint(quantityToMint, price);
              toast.custom(<TransactionSucceedToast />);
            } catch (error: any) {
              // If a user rejects, don't pop a toast.
              // Ideally, we would want to use the name of the URRE object from Wagmi,
              // But this will suffice for now since that object is coming back with "n" as the name.
              if (error.name === 'UserRejectedRequestError') {
                return;
              }

              toast.custom(<ErrorToast message={error.name} />);
            }
          }}
        >
          {getBidButtonText(
            isWaitingForMint,
            isSendingMintTransaction,
            isConnected,
          )}
        </CraButton>
      </div>
      {!isConnected && (
        <div>
          <p className="mt-3 text-center text-sm text-red-500">
            Please Connect Wallet To Mint
          </p>
        </div>
      )}
      <FlatMintNftPayButton />
      <FlatMintSuccessModal
        open={showSuccessModal}
        onCloseModal={() => setShowSuccessModal(false)}
      />
    </div>
  );
}
