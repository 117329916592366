import { ReactComponent as YozLabsLogo } from '../../images/YozLabsLogo.svg';

export default function AocFooter() {
  return (
    <div className="mt-auto flex flex-col items-center justify-center bg-black py-4">
      <div className="-mb-4 text-sm">Powered by</div>
      <YozLabsLogo className="-mb-2 w-40" />
    </div>
  );
}
