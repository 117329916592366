import { useState } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';

interface ErrorToastProps {
  message?: string;
}

/**
 * Component for a toast that pops up when submitting a transaction fails for a user.
 */
export default function ErrorToast(props: ErrorToastProps) {
  const [toastViewable, setToastViewable] = useState<boolean>(true);
  const { message } = props;

  return (
    <>
      {toastViewable && (
        <div
          className={
            'flex inline-flex w-[500px] flex-row items-center rounded-lg bg-white shadow-md'
          }
        >
          <div className="mr-2 rounded-l-lg bg-[#DA1212] p-3">
            <ExclamationCircleIcon className="h-6 w-6" />
          </div>
          <div className="flex w-full flex-row items-center justify-between">
            <p className="text-sm font-bold text-black">
              Uh oh, something went wrong! {message && <span>{message}</span>}
            </p>
            <div className="float-right mr-4 flex">
              <button
                onClick={() => setToastViewable(false)}
                className="my-auto"
              >
                <XIcon className="h-4 w-4 fill-black" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
