import { BigNumber, ethers } from 'ethers';

export type FlatMintEvent = ethers.Event & {
  args: {
    price: BigNumber;
    quantity: BigNumber;
  };
};

export function isFlatMintEvent(o: unknown): o is FlatMintEvent {
  if (typeof o !== 'object') {
    return false;
  }

  const potentialFlatMintEvent = o as FlatMintEvent;
  return (
    BigNumber.isBigNumber(potentialFlatMintEvent.args.price) &&
    BigNumber.isBigNumber(potentialFlatMintEvent.args.quantity)
  );
}
