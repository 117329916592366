export const THREE_MINUTES_IN_MS = 3 * 60000;
export const TEN_MINUTES_IN_MS = 10 * 60000;

/**
 * A config for use with wagmi.useContractRead for data that does not need
 * to be read more than once because it will not change.
 */
export const fetchOnceReadConfig = {
  // Cache for 10 minutes or so; that should give us room
  // for this to be garbage-collected, but also keep the data
  // around. Infinity may be a reasonable option here, but
  // let's start here.
  cacheTime: TEN_MINUTES_IN_MS,

  // Data is re-fetched if a stale query is called. Thus, for queries
  // that we only want to fetch once (when the app first loads), we
  // should make sure it's never stale.
  staleTime: Infinity,

  // This data does not need to be watched for changes; once we do a fetch,
  // we expect this value to not change.kkk:2
  watch: false,
};
