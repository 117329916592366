import AocNavBar from './AocNavBar';
import { useConnect, useNetwork } from 'wagmi';
import { getChain } from '../../util/envUtil';
import AocFooter from './AocFooter';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

interface AocLayoutProps {
  children: React.ReactNode;
}

const appChain = getChain();

export default function AocLayout({ children }: AocLayoutProps) {
  const { isConnected } = useConnect();
  const { activeChain, switchNetwork } = useNetwork();
  return (
    <div className="flex h-full w-full flex-col">
      <div className="p-4">
        <AocNavBar />
      </div>
      {isConnected && activeChain?.id !== appChain.id && switchNetwork && (
        <div className="mb-20 flex w-full flex-row justify-center space-x-2 bg-[#FF0099] p-4 text-sm font-bold text-black">
          <ExclamationCircleIcon className="my-auto h-7 w-7" />
          <p className="my-auto">
            To participate in the mint, you must be connected to {appChain.name}
            {appChain.name === 'Ethereum' && ' Mainnet'}.
          </p>
          <button
            className="my-auto font-bold underline"
            onClick={() => switchNetwork(appChain.id)}
          >
            Connect to {appChain.name}
            {appChain.name === 'Ethereum' && ' Mainnet'}
          </button>
        </div>
      )}
      <div className="mb-16 flex w-full flex-col items-center">{children}</div>
      <AocFooter />
    </div>
  );
}
